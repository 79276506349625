// Hooks
import useErrorReporting from "../../hooks/useErrorReporting";
import {
  useHRCompliantApplicationRequest,
  useHRCompliantApplicationDownload,
} from "../../api/HRCompliantApplication/HRCompliantApplication";

// Components
import Tooltip from "../Tooltip/Tooltip";

// Assets
import RequestCustomApplicationIcon from "../../assets/images/icons/request-custom-application-inactive-icon.svg?react";
import { useEffect, useState } from "react";
import { format } from "date-fns";

interface Props {
  application: {
    id: string | undefined;
    url: string | null;
    request_date: string | null;
  };
}

function ApplicationSendHRCompliantForm({ application }: Props) {
  const errorReporting = useErrorReporting();

  // Send an email to the applicant requesting to upload HR compliant application
  const requestHRCompliantApplication = useHRCompliantApplicationRequest();
  const handleRequestHRCompliantApplication = async () => {
    if (!application.id) return;

    try {
      await requestHRCompliantApplication.mutateAsync(application.id);
    } catch (error) {
      errorReporting("Failed sending request for HR Compliant Application", error, {
        application_id: application.id,
      });
    }
  };

  // Download the HR compliant application uploaded by the applicant
  const downloadHRCompliantApplication = useHRCompliantApplicationDownload();
  const handleDownloadHRCompliantApplication = async () => {
    if (!application.id || !application.url) return;

    try {
      await downloadHRCompliantApplication.mutateAsync(application.id);
    } catch (error) {
      errorReporting("Failed downloading HR Compliant Application", error, {
        application_id: application.id,
      });
    }
  };

  // Control the text that will be shown in the tooltip based on application state
  const [tooltipText, setTooltipText] = useState<string>("Request HR Compliant Application");
  useEffect(() => {
    if (application.request_date && !application.url) {
      const requestDate = format(new Date(application.request_date), "MM/dd/yyyy");
      setTooltipText(
        `HR Compliant Application requested on ${requestDate}. Click to Request Again.`,
      );
      return;
    }

    if (application.url) {
      setTooltipText("Download HR Compliant Application");
      return;
    }
  }, [application]);

  return (
    <Tooltip text={tooltipText} size="lg">
      <div
        className={`applications__socials__icon ${
          application.url ? "applications__socials__icon--active" : ""
        }`}
        onClick={() => {
          if (application.url) {
            handleDownloadHRCompliantApplication();
          } else {
            handleRequestHRCompliantApplication();
          }
        }}
      >
        <RequestCustomApplicationIcon />
      </div>
    </Tooltip>
  );
}

export default ApplicationSendHRCompliantForm;
