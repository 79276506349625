// Constants
import { FORM_FIELD_SUBTYPE_FIELDS_VALIDATIONS, FILE_MAX_BYTES_SIZE } from "./constants";
import {
  SCHEMAS_NO_HTML_MESSAGE_TEXT,
  SchemasFileValueTestValidation,
} from "../../../schemas/constants";

// Interfaces
import { OnlineApplicationFormComponentsFields } from "../../../api/OnlineApplication/interfaces";
import {
  OnlineApplicationFieldsForDynamicValidationSchema,
  OnlineApplicationDynamicFieldsValuesEnum,
  OnlineApplicationSupportedDynamicFieldsValues,
} from "../interfaces";

// Utilities
import * as Yup from "yup";
import { handleStringCapitalization } from "../../../utilities/strings/handleStringCapitalization";
import { handleFormFieldsMaximumCharactersLimit } from "./utilities";
import { handleCheckStringForHTML } from "../../../utilities/strings/handleCheckStringForHTML";

/**
 *
 * Generate validation schema that will be used in the Formik's form, on the fly,
 * based on the received form fields from the API response.
 *
 * @param components List of components that will be used to
 * construct the form, as received from the API response.
 *
 * @returns A `Yup` validation schema object.
 *
 */
export function handleRegularFormFieldsValidation(
  components: OnlineApplicationFormComponentsFields[],
) {
  const validationFields: OnlineApplicationFieldsForDynamicValidationSchema[] = [];

  // Loop trough each of the form fields and extract
  // the necessary information to construct the validation schema
  components.forEach(component => {
    // Skip the sections that are marked as "dynamic"
    if (component.dynamic) return;

    component.section_fields.forEach(field => {
      validationFields.push({
        name: field.name,
        is_required: field.is_required,
        type: field.type,
        sub_type: field.sub_type,
        value_type: OnlineApplicationDynamicFieldsValuesEnum[field.type],
      });
    });
  });

  let validationObject: Record<string, unknown> = {
    terms_and_conditions: Yup.boolean().oneOf([true], "Terms and Conditions must be accepted."),
  };

  // Loop trough fields that will be used for validation,
  // and use the details associated with each of the fields to
  // generate a validation schema
  validationFields.forEach(field => {
    const { name, is_required, value_type, sub_type, type } = field;
    const fieldRequired = is_required ? "required" : "notRequired";
    const fieldValueType: OnlineApplicationSupportedDynamicFieldsValues = value_type;
    const fieldNameSanitized: string = name.split("_").join(" ");
    let fieldRequiredMessage: string = is_required
      ? `${handleStringCapitalization(fieldNameSanitized, [" "])} field is required`
      : "";

    // Because different labels are being used for the following fields based on
    // current "country" field selection (US / CA), we update the message that will be
    // used for validating these fiels to accomodate more general use cases.
    if (["city", "state", "zip_code"].includes(name) && is_required) {
      fieldRequiredMessage = "This field is required.";
    }

    // If the field has a sub_type, then add a validation schema specific
    // for this field's sub type value (e.g. linked in, profile photo, etc.)
    if (sub_type) {
      validationObject = {
        ...validationObject,
        [name]: FORM_FIELD_SUBTYPE_FIELDS_VALIDATIONS[sub_type][fieldRequired],
      };
      return;
    }

    // Handle validation schemas for "file" type of inputs
    if (fieldValueType == "file") {
      validationObject = {
        ...validationObject,
        [name]: Yup.mixed()
          .nullable()
          .test(
            `${name}-file-size`,
            `${handleStringCapitalization(fieldNameSanitized, [
              " ",
            ])} file must be up to a maximum of 8 MB in size`,
            value => {
              // If the value is null (default value) then no validation is applied
              if (value == null) return true;

              // If the file is bigger than 8mb, throw error, otherwise test passes
              return (value as SchemasFileValueTestValidation).size <= FILE_MAX_BYTES_SIZE;
            },
          )
          [fieldRequired](fieldRequiredMessage),
      };

      return;
    }

    if (fieldValueType === "datetime") {
      validationObject = {
        ...validationObject,
        [name]: Yup.date()[fieldRequired](fieldRequiredMessage),
      };
      return;
    }

    if (name === "email") {
      validationObject = {
        ...validationObject,
        [name]: Yup.string()
          .email(`${handleStringCapitalization(name, [" "])} must be a valid email.`)
          .max(50, "Maximum of 50 characters allowed!")
          [fieldRequired](fieldRequiredMessage),
      };
      return;
    }

    if (name === "phone") {
      validationObject = {
        ...validationObject,
        [name]: Yup.string()
          .test("phone-number", "Please enter a valid phone number!", phone => {
            // Throw an error if there's no value provided
            if (!phone) return false;

            // Throw an error if there are not enough characters for valid phone number
            // This phone value is already sanitized from potential brackets, empty spaces and dashes
            // and has an appended +1 prefix to it, that's why the check is set for less than 12 characters
            if (phone.length < 12) return false;

            return true;
          })
          [fieldRequired](fieldRequiredMessage),
      };
      return;
    }

    // Character limitations for text-based fields
    const charachterLimit = handleFormFieldsMaximumCharactersLimit(type);

    // Append the field with the corresponding validation schema
    validationObject = {
      ...validationObject,
      [name]:
        fieldValueType === "string"
          ? Yup.string()
              .max(charachterLimit, `Maximum of ${charachterLimit} characters is allowed!`)
              .test(`${fieldNameSanitized}`, SCHEMAS_NO_HTML_MESSAGE_TEXT, value => {
                // Do not run this test if the value is not a string
                if (typeof value !== "string") return true;

                return !handleCheckStringForHTML(value as string);
              })
              [fieldRequired](fieldRequiredMessage)
          : Yup[fieldValueType]()[fieldRequired](fieldRequiredMessage),
    };
  });

  // Initialize the Yup validation object and return it
  // to be used in the dynamic form
  return validationObject;
}
