import { DropdownItem } from "../Dropdown/interfaces";
import { BsStarFill as StarFullIcon, BsStarHalf as StarHalfIcon } from "react-icons/bs";

export const APPLICAITON_DETAILS_FILTERS: DropdownItem[] = [
  { text: "LinkedIn", value: "linkedin" },
  { text: "Resume", value: "resume" },
  { text: "SBCA", value: "sbca" },
];

export const APPLICATION_SBCA_RANGES = {
  caregiver: "Range: 20-30",
  thinker: "Range: 30-40",
  processor: "Range: 30-40",
};

export const APPLICATION_RATINGS: DropdownItem[] = [
  {
    text: "5.0 - Great",
    value: 5,
    icon: (
      <>
        <StarFullIcon />
        <StarFullIcon />
        <StarFullIcon />
        <StarFullIcon />
        <StarFullIcon />
      </>
    ),
  },
  {
    text: "4.5",
    value: 4.5,
    icon: (
      <>
        <StarFullIcon />
        <StarFullIcon />
        <StarFullIcon />
        <StarFullIcon />
        <StarHalfIcon />
      </>
    ),
  },
  {
    text: "4",
    value: 4,
    icon: (
      <>
        <StarFullIcon />
        <StarFullIcon />
        <StarFullIcon />
        <StarFullIcon />
      </>
    ),
  },
  {
    text: "3.5",
    value: 3.5,
    icon: (
      <>
        <StarFullIcon />
        <StarFullIcon />
        <StarFullIcon />
        <StarHalfIcon />
      </>
    ),
  },
  {
    text: "3 - Average",
    value: 3,
    icon: (
      <>
        <StarFullIcon />
        <StarFullIcon />
        <StarFullIcon />
      </>
    ),
  },
  {
    text: "2.5",
    value: 2.5,
    icon: (
      <>
        <StarFullIcon />
        <StarFullIcon />
        <StarHalfIcon />
      </>
    ),
  },
  {
    text: "2",
    value: 2,
    icon: (
      <>
        <StarFullIcon />
        <StarFullIcon />
      </>
    ),
  },
  {
    text: "1.5",
    value: 1.5,
    icon: (
      <>
        <StarFullIcon />
        <StarHalfIcon />
      </>
    ),
  },
  {
    text: "1 - Poor",
    value: 1,
    icon: (
      <>
        <StarFullIcon />
      </>
    ),
  },
];
