// Utilities & Hooks
import {
  useHRCompliantApplicationCompanyFormGet,
  useHRCompliantApplicationCompanyFormUpload,
} from "../../api/HRCompliantApplication/HRCompliantApplication";
import useErrorReporting from "../../hooks/useErrorReporting";
import handlePermissionCheck from "../../utilities/handlePermissionCheck";

// Components
import { Field, Form, Formik, FormikValues } from "formik";
import ContentHeader from "../../components/Content/ContentHeader";
import FormUpload from "../../components/Form/FormUpload";
import Button from "../../components/Button/Button";
import Alert from "../../components/Alert/Alert";
import Loader from "../../components/Loader/Loader";

// Schemas
import { HR_COMPLIANT_APPLICATION_FORM_UPLOAD_SCHEMA } from "../../schemas/HRCompliantSchemas";

function HRCompliantFormCompanyUpload() {
  const errorReporting = useErrorReporting();

  // Check if there's already a form uploaded
  const { data, isLoading } = useHRCompliantApplicationCompanyFormGet();

  // Upload new form
  const uploadForm = useHRCompliantApplicationCompanyFormUpload();
  const handleUploadForm = async (values: FormikValues) => {
    try {
      await uploadForm.mutateAsync(values.pdf);
    } catch (error) {
      errorReporting("Failed uploading company HR Compliant Application form", error);
    }
  };

  if (isLoading) {
    return (
      <div className="container py--25">
        <Loader size="page" modifierWrapper="loader--page" />
      </div>
    );
  }

  if (!handlePermissionCheck(["hr_compliant_form_upload"])) {
    return (
      <div className="container py--25">
        <h3 className="fw--semibold mb--10">Unauthorized Request</h3>
        <p>
          You don't have the necessary authorization to upload an HR Compliant Application Form on
          company level.{" "}
        </p>
      </div>
    );
  }

  return (
    <div className="container py--25">
      <ContentHeader title="HR Compliant Form" modifierClass="content__header--no-underline" />

      <p>
        If your company has an HR Compliant Application form that needs to be used, you can upload
        it here.
      </p>

      {data?.has_form ? (
        <Alert
          text="HR Compliant Application form is already uploaded for this company. Uploading a new one will overwrite the existing one."
          type="info"
          modifierClass="alert--txt-black"
        />
      ) : null}

      <div className="row">
        <div className="col-12 col-lg-6">
          <Formik
            initialValues={{ pdf: null }}
            validationSchema={HR_COMPLIANT_APPLICATION_FORM_UPLOAD_SCHEMA}
            onSubmit={handleUploadForm}
          >
            {({ values, errors }) => (
              <Form>
                <Field
                  component={FormUpload}
                  id="pdf"
                  name="pdf"
                  buttonText="Choose File"
                  placeholder="No file chosen"
                  modifierClass="input--box-error"
                  accept=".pdf"
                  uploadType="image"
                  fieldHasDedicatedFileTypeValidation
                  info={
                    <>
                      <span className="input-upload__info--info">Supported file types: .pdf</span>
                    </>
                  }
                />

                <Button
                  modifierClass="btn--fluid btn--fluid--md btn--primary"
                  isDisabled={uploadForm.isLoading || !values.pdf || errors.pdf}
                  isLoading={uploadForm.isLoading}
                >
                  Upload Form
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default HRCompliantFormCompanyUpload;
