import * as Yup from "yup";
import handleExtractFileExtension from "../utilities/strings/handleExtractFileExtension";
import { SchemasFileValueTestValidation } from "./constants";
import { FILE_MAX_BYTES_SIZE } from "../pages/OnlineApplication/form-builder/constants";

const FILE_EXTENSIONS_HR_COMPLIANT_FORM: string[] = ["pdf"];

export const HR_COMPLIANT_APPLICATION_FORM_UPLOAD_SCHEMA = Yup.object().shape({
  pdf: Yup.mixed()
    .nullable()
    .test(
      "hr-compliant-application-form",
      "HR Compliant Application form PDF must be selected.",
      value => {
        if (!value) return false;
        return true;
      },
    )
    .test(
      "hr-compliant-application-form-extension",
      "PDF validation failed: Allowed file types are PDF.\n\nPlease correct the issue mentioned above and upload again.",
      value => {
        // If the value is null (default value) then no validation is applied
        if (!value) return false;

        // Extract the file extension from the selected file
        const fileExtension: string = handleExtractFileExtension(
          (value as SchemasFileValueTestValidation).name,
        );

        // If file extension is not allowed, throw an error
        if (!FILE_EXTENSIONS_HR_COMPLIANT_FORM.includes(fileExtension)) return false;

        return true;
      },
    )
    .test(
      "hr-compliant-application-form-size",
      "HR Compliant Application form PDF must be up to a maximum of 8mb in size.",
      value => {
        // If the value is null (default value) then no validation is applied
        if (!value) return false;

        // If the file is bigger than 8mb, throw error, otherwise test passes
        return (value as SchemasFileValueTestValidation).size <= FILE_MAX_BYTES_SIZE;
      },
    ),
});
