// Utilities & Hooks
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useHRCompliantApplicationDownloadForm,
  useHRCompliantApplicationGetInfo,
  useHRCompliantApplicationUserUpload,
} from "../../api/HRCompliantApplication/HRCompliantApplication";
import useErrorReporting from "../../hooks/useErrorReporting";

// Components
import { Field, Form, Formik, FormikValues } from "formik";
import Button from "../../components/Button/Button";
import FormUpload from "../../components/Form/FormUpload";
import OnlineApplicationFooter from "../../components/OnlineApplications/OnlnieApplicationFooter";
import Loader from "../../components/Loader/Loader";

// Schemas
import { HR_COMPLIANT_APPLICATION_FORM_UPLOAD_SCHEMA } from "../../schemas/HRCompliantSchemas";

const OnlineApplicationHRCompliantForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const errorReporting = useErrorReporting();

  /*===========================
    INFO DETAILS
  ============================*/
  const { data: infoData, isLoading: infoIsLoading, error } = useHRCompliantApplicationGetInfo(id);

  useEffect(() => {
    if (!error) return;
    navigate("/404/");
  }, [error]);

  /*===========================
    DOWNLOAD THE FORM PDF
  ============================*/
  const downloadHRCompliantFormPDF = useHRCompliantApplicationDownloadForm();

  const handleDownloadPDF = async () => {
    if (!id) return;

    try {
      await downloadHRCompliantFormPDF.mutateAsync(id);
    } catch (error) {
      errorReporting("Failed downloading non-filled out HR Compliant Application", { error, id });
    }
  };

  /*===========================
    TRIGGER PHOTO UPLOAD
  ============================*/
  const uploadHRCompliantApplication = useHRCompliantApplicationUserUpload();

  const handleUploadPDF = async (values: FormikValues) => {
    // If there's no valid identifier received, prevent form submission
    if (!id) return;

    try {
      await uploadHRCompliantApplication.mutateAsync({ id: id, pdf: values.pdf });

      // Redirect the user to the Thank You page
      navigate("/online-application/upload/application/thank-you/");
    } catch (error) {
      errorReporting("Failed uploading HR Compliant Application", error, { ...values });
    }
  };

  return (
    <div className="online-application-upload">
      {infoIsLoading ? (
        <Loader size="page" modifierWrapper="loader--page" />
      ) : infoData?.is_compliant_form_uploaded ? (
        <div className="container">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h1 className="fw--semibold txt--center mb--30">
              HR Compliant Application form has already been uploaded
            </h1>

            <p className="online-application-upload__text txt--center mb--50">
              Thank you for completing the job application. You have already uploaded your HR
              Compliant Application form. <br /> We appreciate your interest in this career
              opportunity.
            </p>
          </div>
        </div>
      ) : (
        <div className="container">
          <h1 className="online-application-upload__title">{infoData?.company || "N/A"}</h1>

          <h3 className="online-application-upload__subtitle">Upload HR Compliant Application</h3>
          <p className="online-application-upload__text">
            We appreciate your interest in this career opportunity. Please use the button below to
            download the HR Compliant Application form.
          </p>

          <Button modifierClass="btn--fluid btn--primary mb--20i" onClick={handleDownloadPDF}>
            Download Form
          </Button>

          <p className="online-application-upload__text mb--30">
            After filling out the HR Compliant Application form, please use the button below to
            upload the PDF.
          </p>

          <Formik
            initialValues={{ pdf: null }}
            validationSchema={HR_COMPLIANT_APPLICATION_FORM_UPLOAD_SCHEMA}
            onSubmit={handleUploadPDF}
          >
            {({ values, errors }) => (
              <Form>
                <Field
                  component={FormUpload}
                  id="pdf"
                  name="pdf"
                  buttonText="Choose File"
                  label="HR Compliant Application PDF"
                  placeholder="No file chosen"
                  modifierClass="input--box-error"
                  accept=".pdf"
                  uploadType="image"
                  fieldHasDedicatedFileTypeValidation
                  info={
                    <>
                      <span className="input-upload__info--info">Supported file types: .pdf</span>
                    </>
                  }
                />

                <p className="online-application-upload__text">
                  Please add no-reply@firstchoicehiring.com to your address book to ensure you
                  receive valuable communication from us in the future.
                </p>

                <Button
                  modifierClass="btn--fluid btn--fluid--md btn--secondary"
                  isDisabled={uploadHRCompliantApplication.isLoading || !values.pdf || errors.pdf}
                  isLoading={uploadHRCompliantApplication.isLoading}
                >
                  Upload Application
                </Button>
              </Form>
            )}
          </Formik>

          <OnlineApplicationFooter />
        </div>
      )}
    </div>
  );
};

export default OnlineApplicationHRCompliantForm;
