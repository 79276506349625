// Hooks & Utilities
import { useEffect, useMemo, useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { matchSorter } from "match-sorter";
import { useExtractSearchParameters } from "../../hooks/useExtractSearchParameters";
import { useBucketsGetAll } from "../../api/Buckets/Buckets";
import { useCompaniesGetAvailablePositions } from "../../api/Company/Company";
import { format, subDays } from "date-fns";
import { handleApplicantCityAndState } from "../../utilities/strings/handleApplicantCityAndState";
import { useReportGetHiring } from "../../api/Reports/Reports";
import { useReportDateRangeHandling } from "./hooks/useReportDateRangeHandling";
import { orderBuckets } from "../../components/Applications/Buckets/orderBuckets";
import handleInitiateSpreadsheetGenerator from "../../utilities/data/handleInitiateSpreadsheetGenerator";

// Components
import Card from "../../components/Card/Card";
import Button from "../../components/Button/Button";
import InputFieldSearch from "../../components/Inputs/InputFieldSearch";
import Dropdown from "../../components/Dropdown/Dropdown";
import { Link } from "react-router-dom";
import Table from "../../components/Table/Table";
import DropdownSearchable from "../../components/Dropdown/DropdownSearchable";
import Datepicker from "../../components/Datepicker/Datepicker";
import Loader from "../../components/Loader/Loader";
import Tooltip from "../../components/Tooltip/Tooltip";

// Assets
import { AiOutlineDoubleLeft as PreviousButton } from "react-icons/ai";
import { GrPowerReset as ResetFiltersIcon } from "react-icons/gr";

// Interfaces & Constants
import {
  REPORTS_HIRING_DOCUMENT_COLUMNS,
  REPORTS_ROWS_PER_PAGE_DROPDOWN_OPTIONS,
  REPORT_API_DATE_TYPE_OPTIONS,
} from "./constants";
import { ReportHiringTableData } from "./interfaces";
import { DropdownItem } from "../../components/Dropdown/interfaces";
import { handleStringCapitalization } from "../../utilities/strings/handleStringCapitalization";

const HiringReport = () => {
  /*==============================
    SEARCH PARAMETERS
  ===============================*/
  const [searchParametersObject, setSearchParameters] = useExtractSearchParameters();

  /*=====================================
    DATE RANGE FILTERS
    =====================================*/
  const {
    pickerDates,
    filterDates,
    setFilterDates,
    setPickerDates,
    handleStartDate,
    handleEndDate,
  } = useReportDateRangeHandling({
    initial_start_date: format(subDays(Date.now(), 30), "MM/dd/yyyy"),
    initial_end_date: format(Date.now(), "MM/dd/yyyy"),
  });

  /*=================================
    TABLE COLUMNS
  ==================================*/
  const COLUMN_HELPER = createColumnHelper<ReportHiringTableData>();

  const REPORT_TABLE_COLUMNS = [
    COLUMN_HELPER.accessor("job_code", {
      header: () => <span>Job ID</span>,
      size: 130,
      enableSorting: true,
      cell: data => <span>{data.getValue()}</span>,
    }),
    COLUMN_HELPER.accessor("job_title", {
      header: () => <span>Job Title</span>,
      size: 270,
      enableSorting: true,
      cell: data => <span>{data.getValue()}</span>,
    }),
    COLUMN_HELPER.accessor("applicant_name", {
      header: () => <span>Name</span>,
      size: 200,
      enableSorting: true,
      cell: data => (
        <span>
          <Link
            to={`/applications/${data.row.original.application_id}`}
            className="d-flex align-items-center"
          >
            {data.getValue()}
          </Link>
        </span>
      ),
    }),
    COLUMN_HELPER.accessor("resume", {
      header: () => <span>Has Resume?</span>,
      size: 120,
      enableSorting: true,
      cell: data => <span className="txt--capitalize">{data.getValue()}</span>,
    }),
    COLUMN_HELPER.accessor("sbca_score", {
      header: () => <span>SBCA</span>,
      size: 110,
      enableSorting: true,
      cell: data => <span>{data.getValue()}</span>,
    }),
    COLUMN_HELPER.accessor("date_published", {
      header: () => <span>Date Published</span>,
      size: 160,
      enableSorting: true,
      cell: data => {
        return (
          <span>
            {data.getValue() !== "—" ? format(new Date(+data.getValue()), "MM/dd/yyyy") : "—"}
          </span>
        );
      },
    }),
    COLUMN_HELPER.accessor("date_received", {
      header: () => <span>Date Received</span>,
      size: 160,
      enableSorting: true,
      sortingFn: "datetime",
      cell: data => {
        return <span>{format(data.getValue(), "MM/dd/yyyy")}</span>;
      },
    }),
    COLUMN_HELPER.accessor("address", {
      header: () => <span>Address</span>,
      size: 200,
      enableSorting: true,
      cell: data => <span>{data.getValue()}</span>,
    }),
    COLUMN_HELPER.accessor("bucket", {
      header: () => <span>Status</span>,
      size: 130,
      enableSorting: true,
      cell: data => <span>{data.getValue()}</span>,
    }),
    COLUMN_HELPER.accessor("hired_date", {
      header: () => <span>Hired Date</span>,
      size: 130,
      enableSorting: true,
      cell: data => {
        return (
          <span>
            {data.getValue() !== "—" ? format(new Date(+data.getValue()), "MM/dd/yyyy") : "—"}
          </span>
        );
      },
    }),
    COLUMN_HELPER.accessor("days_elapsed", {
      header: () => <span>Days elapsed</span>,
      size: 130,
      enableSorting: true,
      sortingFn: "alphanumeric",
      cell: data => <span>{data.getValue()}</span>,
    }),
  ];

  /*====================================================
    DATE FILTERING TYPE
  =====================================================*/
  const [dateType, setDateType] = useState<"published" | "received">(
    searchParametersObject.date_type || "published",
  );

  const handleChangeDateType = (newOption: DropdownItem) => {
    setSearchParameters({ ...searchParametersObject, date_type: newOption.value });
    setDateType(newOption.value as "published" | "received");
  };

  /*=================================
    FETCH REPORT DATA
  ==================================*/
  const {
    data: hiringData,
    isLoading: hiringLoading,
    isFetching: hiringFetching,
  } = useReportGetHiring(dateType, filterDates);
  /*============================================
    SORTED DATA EXPORTED FROM TABLE COMPONENT
  =============================================*/
  const [exportedTableData, setExportedTableData] = useState<ReportHiringTableData[]>([]);

  /*====================================================
    TABLE ROWS PER PAGE CONTROL
  =====================================================*/
  const [rowsPerPage, setRowsPerPage] = useState<number>(100);

  /*======================================
    BUCKETS DATA
  ======================================*/
  const { data: bucketsData, isLoading: bucketsLoading } = useBucketsGetAll();
  const [bucketsDropdown, setBucketsDropdown] = useState<DropdownItem[]>([]);
  const [totalApplicationsCount, setTotalApplicationsCount] = useState<number>(0);

  useEffect(() => {
    // Exit function if there are no buckets available
    if (!bucketsData || !bucketsData.length || bucketsLoading || !hiringData) return;

    const ORDERED_BUCKETS: DropdownItem[] = orderBuckets(bucketsData);

    const MAPPED_BUCKETS = ORDERED_BUCKETS.map(bucket => {
      return {
        ...bucket,
        text: `${bucket.text} [${
          bucketsData.find(item => item.id === bucket.value)?.applications_count
        }]`,
      };
    });

    // Get the total applications count from  the sum of the bucketsData
    const allBucketsCount = bucketsData.reduce(
      (accumulator, currentValue) => accumulator + currentValue.applications_count,
      0,
    );

    // Update the state designated to reflect the total unfiltered applications count
    setTotalApplicationsCount(allBucketsCount);

    // Append the 'All' option at the beginning
    setBucketsDropdown([
      { text: `All Buckets [${allBucketsCount}]`, value: "all" },
      ...MAPPED_BUCKETS,
    ]);
  }, [bucketsData, hiringData]);

  /*====================================================
    JOB TITLES DATA
  ====================================================*/
  const { data: jobTitlesData, isLoading: jobTitlesLoading } = useCompaniesGetAvailablePositions();

  const [dropdownJobTitles, setDropdownJobTitles] = useState<DropdownItem[]>([]);

  useEffect(() => {
    if (!jobTitlesData || !jobTitlesData.length || jobTitlesLoading) return;

    // Alphabetically sort the "jobs" dropdown items
    const sortedJobs = jobTitlesData
      .sort((jobTitleA, jobTitleB) => {
        return jobTitleA.toLowerCase() > jobTitleB.toLowerCase() ? 1 : -1;
      })
      .map(jobTitle => {
        return { text: jobTitle, value: jobTitle };
      });

    setDropdownJobTitles([{ text: "All Job Titles", value: "all" }, ...sortedJobs]);
  }, [jobTitlesData]);

  /*====================================================
    RESUME STATUS DROPDOWN OPTIONS
  ====================================================*/
  const RESUME_DROPDOWN_OPTIONS: DropdownItem[] = [
    { text: "All resume statuses", value: "all" },
    { text: "With resume", value: "yes" },
    { text: "Without resume", value: "no" },
  ];

  /*====================================================
    SBCA STATUS DROPDOWN OPTIONS
  ====================================================*/
  const SBCA_DROPDOWN_OPTIONS: DropdownItem[] = [
    { text: "All SBCA statuses", value: "all" },
    { text: "With SBCA", value: "yes" },
    { text: "Without SBCA", value: "no" },
  ];

  /*====================================================
    STATE & CITY DROPDOWN OPTIONS
  ====================================================*/
  const STATE_AND_CITY_DROPDOWN_OPTIONS = useMemo(() => {
    let API_REPORT_DATA = hiringData || [];

    // Filter API data by DATE RANGE so we only get the states/cities that exist within the selected date range
    if (pickerDates.start_date) {
      const selectedDate = format(new Date(pickerDates.start_date), "T");

      API_REPORT_DATA = API_REPORT_DATA.filter(
        entity => format(new Date(entity.date_received), "T") >= selectedDate,
      );
    }

    if (pickerDates.end_date) {
      const selectedDate = format(new Date(pickerDates.end_date), "T");

      API_REPORT_DATA = API_REPORT_DATA.filter(
        entity => format(new Date(entity.date_received), "T") <= selectedDate,
      );
    }

    // Step #1 - Filter out the empty states/cities
    // Step #2 - Map only the states/cities from the received data
    // Step #3 - Turn to set to remove duplicates & map to array
    // Step #4 - Map as dropdown options
    const ALL_CITIES = Array.from(
      new Set(
        API_REPORT_DATA.filter(item => item.applicant.city).map(item => {
          return item.applicant.city.toLowerCase().trim();
        }),
      ),
    );

    const ALL_STATES = Array.from(
      new Set(
        API_REPORT_DATA.filter(item => item.applicant.state).map(item => {
          return item.applicant.state.trim();
        }),
      ),
    );

    const CITY_OPTIONS = ALL_CITIES.map(city => {
      return { text: handleStringCapitalization(city, [" ", "-"]), value: city };
    });

    const STATE_OPTIONS = ALL_STATES.map(state => {
      return { text: state, value: state };
    });

    const DROPDOWN_OPTIONS = {
      STATE_DROPDOWN_OPTIONS: [{ text: "All States", value: "all" }, ...STATE_OPTIONS],
      CITY_DROPDOWN_OPTIONS: [{ text: "All Cities", value: "all" }, ...CITY_OPTIONS],
    };

    return DROPDOWN_OPTIONS;
  }, [hiringData, pickerDates]);

  /*===========================
    FILTERING
  ===========================*/
  const [jobTitleFilter, setJobTitleFilter] = useState<string>("all");
  const [bucketFilter, setBucketFilter] = useState<number | "all">("all");
  const [resumeFilter, setResumeFilter] = useState<string>("all");
  const [sbcaFilter, setSbcaFilter] = useState<string>("all");
  const [stateFilter, setStateFilter] = useState<string>("all");
  const [cityFilter, setCityFilter] = useState<string>("all");

  // In case the provided bucket id URL parameter is not valid, remove the parameter
  useEffect(() => {
    if (bucketFilter === "all" || !bucketsData || !bucketsData.length) return;

    if (bucketsData.findIndex(item => item.id === bucketFilter) < 0) {
      setBucketFilter("all");
      delete searchParametersObject.bucket;

      setSearchParameters({ ...searchParametersObject });
    }
  }, [bucketsData, bucketFilter]);

  const handleCityFilter = (city: string) => {
    setCityFilter(city);

    // Append the "city" filter route parameter
    if (city !== "all") {
      setSearchParameters({ ...searchParametersObject, city: city });
    } else {
      delete searchParametersObject.city;
      setSearchParameters({ ...searchParametersObject });
    }
  };

  const handleStateFilter = (state: string) => {
    setStateFilter(state);

    // Append the "state" filter route parameter
    if (state !== "all") {
      setSearchParameters({ ...searchParametersObject, state: state });
    } else {
      delete searchParametersObject.state;
      setSearchParameters({ ...searchParametersObject });
    }
  };

  const handleSbcaFilter = (presentSBCA: string) => {
    setSbcaFilter(presentSBCA);

    // Append the "sbca" filter route parameter
    if (presentSBCA !== "all") {
      setSearchParameters({ ...searchParametersObject, sbca: presentSBCA });
    } else {
      delete searchParametersObject.sbca;
      setSearchParameters({ ...searchParametersObject });
    }
  };

  const handleJobAdsTitleFilter = (title: string) => {
    setJobTitleFilter(title);

    // Append the "title" filter route parameter
    if (title !== "all") {
      setSearchParameters({ ...searchParametersObject, jobTitle: title });
    } else {
      delete searchParametersObject.jobTitle;
      setSearchParameters({ ...searchParametersObject });
    }
  };

  const handleBucketFilter = (bucket: number | "all") => {
    setBucketFilter(bucket);

    if (bucket !== "all") {
      setSearchParameters({ ...searchParametersObject, bucket: bucket });
    } else {
      delete searchParametersObject.bucket;
      setSearchParameters({ ...searchParametersObject });
    }
  };

  const handleResumeFilter = (presentResume: string) => {
    setResumeFilter(presentResume);

    // Append the "resume" filter route parameter
    if (presentResume !== "all") {
      setSearchParameters({ ...searchParametersObject, resume: presentResume });
    } else {
      delete searchParametersObject.resume;
      setSearchParameters({ ...searchParametersObject });
    }
  };

  // Search Params filter preselection
  useEffect(() => {
    const { jobTitle, bucket, resume, sbca, city, state } = searchParametersObject as Record<
      "jobTitle" | "bucket" | "resume" | "sbca" | "city" | "state",
      string | undefined
    >;

    if (jobTitle) setJobTitleFilter(jobTitle);
    if (bucket && !isNaN(Number(bucket))) {
      setBucketFilter(Number(bucket));
    } else {
      delete searchParametersObject.bucket;
      setSearchParameters({ ...searchParametersObject });
    }
    if (resume) setResumeFilter(resume);
    if (sbca) setSbcaFilter(sbca);
    if (city) setCityFilter(city);
    if (state) setStateFilter(state);
  }, []);

  /*============================================
    SEARCH INPUT & REPORT TABLE DATA

    The search input field filters through the 
    "job_id", "job_title", "applicant.first_name",
    "applicant.last_name" data fields. It looks 
    for an exact match between the search input & 
    the "job_id" field value, the "job_title", 
    "applicant.first_name" & "applicant.last_name"
    fields are filtered using partial search.
  =============================================*/
  const [searchedValue, setSearchedValue] = useState<string>("");

  const REPORT_TABLE_DATA = useMemo(() => {
    const API_REPORT_DATA = hiringData || [];

    let REPORT_DATA;

    REPORT_DATA = API_REPORT_DATA.map(entity => {
      return {
        job_code: entity.job_code || "—",
        job_title: entity.job_title || "None (In-House Application)",

        applicant_name: `${entity.applicant.first_name} ${entity.applicant.last_name}` || "N/A",
        application_id: entity.id || "N/A",
        resume: entity.resume || "N/A",
        sbca_score: entity.sbca_score ? entity.sbca_score.toFixed(2) : "—",

        date_published: entity.ad_published_date
          ? format(new Date(entity.ad_published_date), "T")
          : "—",
        date_received: entity.date_received ? new Date(entity.date_received) : "N/A",
        address: `${
          !entity.applicant.city && !entity.applicant.state
            ? "N/A"
            : handleApplicantCityAndState(entity.applicant.city, entity.applicant.state)
        }`,
        city: entity.applicant.city || "—",
        state: entity.applicant.state || "—",
        bucket: entity.bucket || "N/A",
        hired_date: entity.hired_date ? format(new Date(entity.hired_date), "T") : "—",
        days_elapsed: entity.days_elapsed || "—",
      };
    });

    if (searchedValue) {
      REPORT_DATA = matchSorter(REPORT_DATA, searchedValue, {
        keys: [
          { threshold: matchSorter.rankings.STARTS_WITH, key: "job_code" },
          { threshold: matchSorter.rankings.CONTAINS, key: "job_title" },
          { threshold: matchSorter.rankings.CONTAINS, key: "applicant_name" },
        ],
      });
    }

    // Filter data with selected BUCKETS filter
    if (bucketFilter !== "all") {
      const foundBucketName = bucketsData?.find(item => item.id === bucketFilter)?.name;

      REPORT_DATA = REPORT_DATA.filter(entity => entity.bucket === foundBucketName);
    }

    // Filter data with selected JOB TITLE filter
    if (jobTitleFilter !== "all") {
      REPORT_DATA = REPORT_DATA.filter(
        entity => entity.job_title.toLowerCase() === jobTitleFilter.toLowerCase(),
      );
    }

    // Filter data by Resume presence
    if (resumeFilter !== "all") {
      REPORT_DATA = REPORT_DATA.filter(entity => entity.resume === resumeFilter);
    }

    // Filter data by SBCA presence
    if (sbcaFilter !== "all") {
      if (sbcaFilter === "yes") {
        REPORT_DATA = REPORT_DATA.filter(entity => entity.sbca_score !== "—");
      } else {
        REPORT_DATA = REPORT_DATA.filter(entity => entity.sbca_score === "—");
      }
    }
    // Filter data by City
    if (cityFilter !== "all") {
      REPORT_DATA = REPORT_DATA.filter(
        entity => entity.city.toLowerCase().trim() === cityFilter.toLowerCase(),
      );
    }
    // Filter data by State
    if (stateFilter !== "all") {
      REPORT_DATA = REPORT_DATA.filter(entity => entity.state.trim() === stateFilter);
    }

    return REPORT_DATA;
  }, [
    bucketFilter,
    jobTitleFilter,
    searchedValue,
    hiringData,
    resumeFilter,
    sbcaFilter,
    cityFilter,
    stateFilter,
  ]);

  const EXPORT_DATA = useMemo(() => {
    if (!exportedTableData.length) return [];

    const formattedDates = exportedTableData.map(item => {
      return {
        ...item,
        date_published:
          item.date_published && item.date_published !== "—"
            ? format(new Date(+item.date_published), "MM/dd/yyyy")
            : "—",
        date_received: format(new Date(item.date_received), "MM/dd/yyyy"),
        hired_date:
          item.hired_date !== "—" ? format(new Date(+item.hired_date), "MM/dd/yyyy") : "—",
      };
    });

    return formattedDates;
  }, [exportedTableData]);

  /*==========================================
    FILTERS RESET
  ==========================================*/
  const handleResetFilters = () => {
    setJobTitleFilter("all");
    setBucketFilter("all");
    setResumeFilter("all");
    setSbcaFilter("all");
    setSearchedValue("");
    setCityFilter("all");
    setStateFilter("all");

    // Reset date pickers state to initial values
    setFilterDates({
      start_date: format(subDays(Date.now(), 30), "MM/dd/yyyy"),
      end_date: format(Date.now(), "MM/dd/yyyy"),
    });

    setPickerDates({
      start_date: format(subDays(Date.now(), 30), "MM/dd/yyyy"),
      end_date: format(Date.now(), "MM/dd/yyyy"),
    });

    // Remove all search params except newly reset dates
    setSearchParameters({});
  };

  /*==========================================
    TABLE DATA FILTERING INDICATOR
    
    Display a loading indicator while the data is being filtered
    Used for UX only, as the data filtering is almost instant, so the user
    gets feedback that the data has been updated
  ==========================================*/
  const [tableFilteringIndicator, setTableFilteringIndicator] = useState(false);
  useEffect(() => {
    setTableFilteringIndicator(true);

    setTimeout(() => {
      setTableFilteringIndicator(false);
    }, 500);
  }, [REPORT_TABLE_DATA]);

  return (
    <>
      <Card>
        <div className="px--50 px--md--20 d-flex flex-column flex-xl-row justify-content-xl-between">
          <div className="mb--xl--15">
            <h2 className="fw--semibold mb--5 txt--nowrap">Hiring Report</h2>
            <Link to="/reports/" className="d-flex align-items-center txt--link txt--nowrap">
              <PreviousButton className="mr--5" />
              Back to Reports
            </Link>
          </div>

          <div className="row justify-content-end justify-content-lg-between justify-content-xl-end">
            {/* DATE TYPE DROPDOWN */}
            <div className="col-12 col-lg-3 col-md-4">
              <div className="row mb--xl--10 d-flex">
                <div className="d-flex col-12 col-xl-4 pr--0 justify-content-start justify-content-xl-end txt--nowrap">
                  <div className="input__label">By:</div>
                </div>

                <div className="col-12 col-xl-8">
                  <Dropdown
                    title="Select Date Type"
                    preselectedItemValue={dateType}
                    size="lg"
                    items={REPORT_API_DATE_TYPE_OPTIONS}
                    handleItemSelected={item => handleChangeDateType(item)}
                    modifierClass="mb--md--20"
                  />
                </div>
              </div>
            </div>

            {/* START DATE DATEPICKER */}
            <div className="col-12 col-lg-3 col-md-4">
              <div className="row mb--xl--10 d-flex">
                <div className="d-flex col-12 col-xl-4 pr--0 justify-content-start justify-content-xl-end txt--nowrap">
                  <div className="input__label">Start Date:</div>
                </div>

                <div className="col-12 col-xl-8">
                  <Datepicker
                    preselectedDate={
                      pickerDates.start_date ? new Date(pickerDates.start_date) : null
                    }
                    handleSelectedDate={date => handleStartDate(date)}
                    minDate="none"
                    maxDate={pickerDates.end_date ? new Date(pickerDates.end_date) : "none"}
                    enableTime={false}
                    modifierClass="datepicker--lg mb--0i d-flex justify-content-start justify-content-xl-start"
                    placeholder={pickerDates.start_date || "Select start date"}
                    dateFormat="m/d/Y"
                    isDisabled={hiringLoading}
                  />
                </div>
              </div>
            </div>

            {/* END DATE DATEPICKER */}
            <div className="col-12 col-lg-3 col-md-4">
              <div className="row mb--xl--10 d-flex">
                <div className="d-flex col-12 col-xl-4 pr--0 justify-content-start justify-content-xl-end txt--nowrap">
                  <div className="input__label">End Date:</div>
                </div>
                <div className="col-12 col-xl-8">
                  <Datepicker
                    preselectedDate={pickerDates.end_date ? new Date(pickerDates.end_date) : null}
                    handleSelectedDate={date => handleEndDate(date)}
                    minDate={pickerDates.start_date ? new Date(pickerDates.start_date) : "none"}
                    maxDate="none"
                    enableTime={false}
                    modifierClass="datepicker--lg mb--0i d-flex justify-content-start justify-content-xl-start"
                    placeholder={pickerDates.end_date || "Select end date"}
                    dateFormat="m/d/Y"
                    isDisabled={hiringLoading}
                  />
                </div>
              </div>
            </div>
            {/* RUN REPORT BUTTON */}
            <div className="col-12 col-lg-2 col-md-4 d-flex flex-column align-items-xl-end justify-content-md-end align-items-end pr--0 pr--lg--15 pb--15 pb--sm--20 ">
              <Button
                modifierClass="btn--fixed btn--fixed--150 btn--primary fw--semibold"
                onClick={() => setFilterDates(pickerDates)}
                isLoading={hiringLoading || hiringFetching}
                isDisabled={hiringFetching || hiringLoading}
              >
                Run Report
              </Button>
            </div>
          </div>
        </div>
      </Card>

      <div className="px--30 px--lg--10">
        {!hiringLoading ? (
          <>
            <Card modifierClass="card--padding--xl">
              <div className="d-flex flex-column flex-md-row justify-content-between align-items-start">
                {/* RESULTS COUNT */}
                <div className="mr--20 mb--xl--10 mr--lg--0 w--100" style={{ maxWidth: 200 }}>
                  <h5 className="fw--semibold txt--nowrap">
                    Total results: {totalApplicationsCount}
                  </h5>
                  <h5 className="txt--nowrap mb--lg--20">
                    Filtered Results: {REPORT_TABLE_DATA.length}
                  </h5>
                </div>

                {/* FILTERS */}
                <div className="w--100 d-flex flex-column flex-lg-row justify-content-start justify-content-lg-end align-items-start align-items-md-end align-items-lg-start">
                  <div
                    className="d-flex flex-wrap justify-content-start justify-content-md-end mb--10"
                    style={{ gridGap: "10px", maxWidth: "1100px" }}
                  >
                    {/* SEARCH FILTER INPUT */}
                    <InputFieldSearch
                      handleOnSearch={search => setSearchedValue(search)}
                      placeholder="Search Reports"
                      size="md"
                      modifierClass="w-max--100--lg"
                    />

                    {/* JOB TITLE FILTER DROPDOWN */}
                    <DropdownSearchable
                      items={dropdownJobTitles}
                      placeholder={jobTitleFilter === "all" ? "All Job Titles" : jobTitleFilter}
                      preselectedItemValue={jobTitleFilter}
                      size="md"
                      handleItemSelected={job => handleJobAdsTitleFilter(job.value as string)}
                      isLoading={jobTitlesLoading}
                      disabled={jobTitlesLoading || !dropdownJobTitles || !dropdownJobTitles.length}
                    />

                    {/* CITY FILTER DROPDOWN */}
                    <DropdownSearchable
                      items={STATE_AND_CITY_DROPDOWN_OPTIONS.CITY_DROPDOWN_OPTIONS}
                      placeholder={cityFilter === "all" ? "All Cities" : cityFilter}
                      preselectedItemValue={
                        STATE_AND_CITY_DROPDOWN_OPTIONS.CITY_DROPDOWN_OPTIONS.find(
                          item => item.value === cityFilter,
                        )?.value ?? "all"
                      }
                      size="md"
                      handleItemSelected={city => handleCityFilter(city.value as string)}
                      isLoading={hiringLoading}
                      disabled={
                        hiringLoading ||
                        !hiringData ||
                        !STATE_AND_CITY_DROPDOWN_OPTIONS.CITY_DROPDOWN_OPTIONS.length
                      }
                    />

                    {/* STATE FILTER DROPDOWN */}
                    <DropdownSearchable
                      items={STATE_AND_CITY_DROPDOWN_OPTIONS.STATE_DROPDOWN_OPTIONS}
                      placeholder={stateFilter === "all" ? "All States" : stateFilter}
                      preselectedItemValue={
                        STATE_AND_CITY_DROPDOWN_OPTIONS.STATE_DROPDOWN_OPTIONS.find(
                          item => item.value === stateFilter,
                        )?.value ?? "all"
                      }
                      size="md"
                      handleItemSelected={state => handleStateFilter(state.value as string)}
                      isLoading={hiringLoading}
                      disabled={
                        hiringLoading ||
                        !hiringData ||
                        !STATE_AND_CITY_DROPDOWN_OPTIONS.STATE_DROPDOWN_OPTIONS.length
                      }
                    />

                    <DropdownSearchable
                      items={bucketsDropdown}
                      placeholder={
                        bucketFilter === "all" || !bucketsData
                          ? "All Buckets"
                          : bucketsData.find(item => item.id === bucketFilter)?.name ||
                            "All Buckets"
                      }
                      preselectedItemValue={
                        bucketsDropdown?.find(item => item.value === bucketFilter)?.value ?? "all"
                      }
                      size="md"
                      handleItemSelected={bucket => handleBucketFilter(bucket.value as number)}
                      modifierClass="mb--lg--10"
                      isLoading={bucketsLoading}
                      disabled={bucketsLoading || !bucketsData || !bucketsData.length}
                    />

                    {/* RESUME FILTER DROPDOWN */}
                    <Dropdown
                      title="All resume statuses"
                      size="md"
                      items={RESUME_DROPDOWN_OPTIONS}
                      handleItemSelected={(resume: DropdownItem) =>
                        handleResumeFilter(resume.value as string)
                      }
                      preselectedItemValue={resumeFilter}
                    />

                    {/* SBCA FILTER DROPDOWN */}
                    <Dropdown
                      title="All SBCA statuses"
                      size="md"
                      items={SBCA_DROPDOWN_OPTIONS}
                      handleItemSelected={(sbca: DropdownItem) =>
                        handleSbcaFilter(sbca.value as string)
                      }
                      preselectedItemValue={sbcaFilter}
                    />

                    {/* TABLE ROWS DROPDOWN */}
                    <Dropdown
                      title="Select number of rows"
                      preselectedItemValue={100}
                      size="md"
                      items={REPORTS_ROWS_PER_PAGE_DROPDOWN_OPTIONS}
                      handleItemSelected={item => setRowsPerPage(item.value as number)}
                    />
                  </div>

                  <Tooltip text="Reset Filters" wrapperModifierClass="ml--10 ml--md--0 mb--lg--10">
                    <div
                      className="btn--action"
                      onClick={handleResetFilters}
                      style={{ padding: "11px 12px" }}
                      data-testid="action:clear-filters"
                    >
                      <ResetFiltersIcon />
                    </div>
                  </Tooltip>
                </div>
              </div>
            </Card>

            <Card>
              <h3 className="mb--5 px--20 pt--10 txt--center--md">
                <span className="fw--semibold">Export Data - Hiring Report</span> by{" "}
                {dateType === "published" ? "Date Published" : "Date Received"}{" "}
                <span className="fw--semibold">
                  {filterDates.start_date} - {filterDates.end_date}
                </span>
              </h3>
              {dateType === "published" ? (
                <p className="mb--5 px--20 pt--10 txt--center--md">
                  This report does not include Inhouse applications.
                </p>
              ) : null}

              <div className="d-flex flex-column flex-sm-row px--10 pt--10">
                <div>
                  <Button
                    modifierClass="btn--fluid btn--fluid--md btn--primary fw--semibold ml--10 ml--sm--0 mb--sm--15"
                    onClick={() =>
                      handleInitiateSpreadsheetGenerator(
                        EXPORT_DATA,
                        "hiring_report",
                        REPORTS_HIRING_DOCUMENT_COLUMNS,
                        "default-csv",
                        `Applicants by ${
                          dateType === "published" ? "Date Published" : "Date Received"
                        }, FROM DATE: ${filterDates.start_date}, TO DATE: ${filterDates.end_date}`,
                      )
                    }
                    isDisabled={!exportedTableData.length || !hiringData || tableFilteringIndicator}
                  >
                    CSV
                  </Button>
                </div>

                <div>
                  <Button
                    modifierClass="btn--fluid btn--fluid--md btn--primary fw--semibold ml--10 ml--sm--0 mb--sm--15 txt--uppercase"
                    onClick={() =>
                      handleInitiateSpreadsheetGenerator(
                        EXPORT_DATA,
                        "hiring_report",
                        REPORTS_HIRING_DOCUMENT_COLUMNS,
                        "default-excel",
                        `Applicants by ${
                          dateType === "published" ? "Date Published" : "Date Received"
                        }, FROM DATE: ${filterDates.start_date}, TO DATE: ${filterDates.end_date}`,
                      )
                    }
                    isDisabled={!exportedTableData.length || !hiringData || tableFilteringIndicator}
                  >
                    Excel
                  </Button>
                </div>

                {tableFilteringIndicator ? (
                  <div className="d-flex align-items-center ml--sm--0 ml--10">
                    <h6 className="txt--gray--darkest">Filtering table data...</h6>
                    <Loader size="xs" modifierWrapper="ml--10" />
                  </div>
                ) : null}
              </div>

              <Table
                data={REPORT_TABLE_DATA}
                columns={REPORT_TABLE_COLUMNS}
                isRefetching={tableFilteringIndicator}
                paginationPageSize={rowsPerPage}
                handleExportData={data => setExportedTableData(data)}
                modifierClass="table-wrapper--no-shadow mb--0i"
              />
            </Card>
          </>
        ) : (
          <div className="d-flex justify-content-center">
            <Loader size="lg" modifierWrapper="mt--40" />
          </div>
        )}
      </div>
    </>
  );
};

export default HiringReport;
