import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

// Hooks & Utils
import { useAuth } from "../../providers/auth-context";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import handlePermissionCheck from "../../utilities/handlePermissionCheck";

// Interfaces
import { HeaderDropdownGeneralProps } from "./interfaces";

// Assets
import BriefcaseIcon from "../../assets/images/icons/briefcase.svg?react";

// Components
import Tooltip from "../Tooltip/Tooltip";
import { useOnEscapeKey } from "../../hooks/useOnEscapeKey";
import { FRAMER_HEADER_ANIMATION_COMMON, FRAMER_HEADER_TRANSITIONS } from "../../constants/framer";
import { UserRoleNames } from "../../interfaces/global";

const HeaderDropdownWebsite: React.FC<HeaderDropdownGeneralProps> = ({ modifierClass = "" }) => {
  const location = useLocation();

  /*======================
   IN-HOUSE APPLICATION
   FORM LINK
  =======================*/
  const { user } = useAuth();
  const hasCareerPageEditingPermission = handlePermissionCheck(["career_page_edit"]);

  /*======================
   HANDLE DROPDOWN MENU
  =======================*/
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setIsDropdownOpen(false);
  }, [location.pathname]);

  // Close menu if clicked outside
  useOnClickOutside(dropdownRef, () => setIsDropdownOpen(false));

  /*=======================
    CLOSE ON "ESCAPE" KEY
  ========================*/
  useOnEscapeKey(dropdownRef, () => setIsDropdownOpen(false));

  return (
    <Tooltip
      text="In-House Application, Career Pages"
      positioning="bottom"
      size="md"
      modifierClass={isDropdownOpen ? "d-none" : ""}
    >
      <div
        ref={dropdownRef}
        className={`header-dropdown header-dropdown--sm ${modifierClass}`}
        tabIndex={0}
      >
        <div
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          className={`header-dropdown__body ${
            isDropdownOpen ? "header-dropdown__body--active" : ""
          }`}
          data-testid="component:dropdown-header-website"
        >
          <BriefcaseIcon />
        </div>

        <AnimatePresence>
          {isDropdownOpen && (
            <motion.ul
              key="framer-header-website-dropdown"
              className="header-dropdown__content header-dropdown__content--lg header-dropdown__content--website"
              variants={{
                initial: {
                  ...FRAMER_HEADER_ANIMATION_COMMON.initial,
                  translateX: "-50%",
                  top: "150%",
                },
                animate: {
                  ...FRAMER_HEADER_ANIMATION_COMMON.animate,
                  translateX: "-50%",
                  top: "100%",
                },
                exit: {
                  ...FRAMER_HEADER_ANIMATION_COMMON.exit,
                  translateX: "-50%",
                  top: "150%",
                },
              }}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={FRAMER_HEADER_TRANSITIONS}
            >
              <li className="header-dropdown__group">
                <div className="header-dropdown__group__items">
                  <Link
                    className="header-dropdown__link"
                    to={`/online-application/initialize-oa/${user.active_company.form_identifier}`}
                    target="_blank"
                  >
                    In-House Application
                  </Link>

                  {user.role === UserRoleNames.SUPER_ADMIN ? (
                    <Link className="header-dropdown__link" to="/hr-compliant-form/">
                      HR Compliant Form
                    </Link>
                  ) : null}

                  <>
                    {hasCareerPageEditingPermission ? (
                      <Link to="/career/pages/" className="header-dropdown__link">
                        Career Pages
                      </Link>
                    ) : user.active_company.is_career_page_enabled ? (
                      <a
                        className="header-dropdown__link"
                        href={`${import.meta.env.VITE_CAREER_PAGES_URL}/${
                          user.active_company.slug
                        }`}
                        target="_blank"
                        rel="noopener"
                      >
                        Career Pages
                      </a>
                    ) : (
                      <p
                        className="header-dropdown__link header-dropdown__link--disabled"
                        aria-disabled="true"
                      >
                        Career Pages
                      </p>
                    )}
                  </>
                </div>
              </li>
            </motion.ul>
          )}
        </AnimatePresence>
      </div>
    </Tooltip>
  );
};
export default HeaderDropdownWebsite;
